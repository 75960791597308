<template>
    <div class="faq--group">
        <h3 class="size-3" :id="'faq_' + kebabcase(name)">{{ name }}</h3>

        <ul>
            <li v-for="question in questions" :key="'faq_' + question.id" class="pb-r transition">
                <router-link :to="{ name: 'faq_single', params: { id: question.id, name: kebabcase(question.name), question }}" div class="faq--question flex align-center color-accent">
                    <div class="flex-grow">
                        {{ question.name }}
                    </div>
                    <span class="color-secondary">
                        <MenuRightIcon :size="20" />
                    </span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import MenuRightIcon from 'vue-material-design-icons/MenuRight.vue';
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'FAQgroup',
    components: {
        MenuRightIcon,
    },
    props: {
        questions: Array,
        name: String,
    },
    methods: {
        kebabcase,
    },
};
</script>
