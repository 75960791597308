<template>
    <div class="c w-xl content">
        <div class="accentlink s-up:w-10 m-up:w-8 l-up:w-6 xl-up:w-5">
            <h2 class="w-10">{{ data.faq_title_nl }}</h2>
            <div class="color-secondary">
                <span v-html="data.faq_text_nl" class="mr-s" />
            </div>
        </div>

        <div class="flex flex-wrap mt-xxl w-12 l:-w11 xl:w-10">
            <FAQGroup
                class="w-12 l-up:w-6 l-up:pr-xxl mb-xxl"
                v-for="(cat, name) in questions" :key="'faqquestions_' + cat.id.id"
                :questions="cat.questions"
                :name="name"
            />
        </div>
    </div>
</template>

<script>
import FAQGroup from '@/components/FAQGroup.vue';

export default {
    name: 'FAQ',
    components: {
        FAQGroup,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
    data() {
        return {
            questions: [],
        };
    },
    methods: {
        setMeta() {
            if (this.data && this.data.id) {
                this.$store.commit('setMeta', {
                    data: {
                        title: this.data.faq_title_nl,
                        titleDefault: `${process.env.VUE_APP_NAME}`,
                        description: (this.data.faq_text_nl || '').substr(0, 100),
                    },
                });
            }
        },

        getFAQData() {
            const stored = this.$store.getters.faq;
            if (stored.length) {
                this.processData(stored);
            } else {
                this.$store.dispatch('get', 'items/faq/').then(this.processData);
            }
        },

        processData(r) {
            const data = {};
            const processedCats = [];
            r.forEach((question) => {
                if (question.category) {
                    if (processedCats.includes(question.category.id)) {
                        data[question.category.name].questions.push(question);
                    } else {
                        data[question.category.name] = {
                            id: question.category,
                            name: question.category.toString(),
                            questions: [question],
                        };
                        processedCats.push(question.category.id);
                    }
                }
            });
            this.questions = data;
            this.$store.commit('storeFAQ', r);
        },
    },
    created() {
        this.getFAQData();
    },
    watch: {
        data: {
            handler: 'setMeta',
            immediate: true,
        },
    },
};
</script>

<style>

</style>
