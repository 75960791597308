<template>
    <div class="c w-xl content">
        <div class="s-up:w-12 m-up:w-10 l-up:w-8 xl-up:w-7">
            <router-link :to="{ name: 'FAQ' }" class="color-accent">FAQ</router-link>
            <h2 class="w-10">{{ data.name }}</h2>
            <div class="color-secondary" v-html="$options.filters.nl2br(data.answer)" />
        </div>
        <Layout v-if="data.id === 23">
            <template v-slot:content>
                <form-component name="Klachtenformulier" subject-label="Offerte / Ordernummer" />
            </template>
            <template v-slot:sidebar>
                <Sidebar type="complaints" />
            </template>
        </Layout>
    </div>
</template>

<script>
export default {
    name: 'FAQ-Single',
    props: {
        id: [String, Number],
        question: Object,
    },
    components: {
        FormComponent: () => import('@/components/Form.vue'),
        Sidebar: () => import('@/components/Sidebar.vue'),
        Layout: () => import('@/components/Layout-Sidebar.vue'),
    },
    computed: {
        data() {
            return this.question || this.fetchedQuestion;
        },
    },
    data() {
        return {
            fetchedQuestion: {},
        };
    },
    created() {
        if (!this.question) {
            this.getFAQData();
        } else {
            this.setMeta(this.question);
        }
    },
    methods: {
        getFAQData() {
            const stored = this.$store.getters.faq;
            if (stored.length) {
                this.processData(stored.find((it) => it.id.toString() === this.id));
            } else {
                this.$store.dispatch('get', `items/faq/${this.id}`).then(this.processData);
            }
        },

        processData(r) {
            if (r) {
                this.fetchedQuestion = r;
                this.setMeta(r);
            }
        },

        setMeta(r) {
            this.$store.commit('setMeta', {
                data: {
                    title: r.name,
                    titleDefault: `${process.env.VUE_APP_NAME}`,
                    description: (r.answer || '').substr(0, 100),
                },
            });
        },
    },
};
</script>

<style>
</style>
